<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Nama Lengkap *
                </label>
                <InputText type="text" placeholder="Nama Lengkap" v-model="form.fullname" :disabled="form.is_submit" />
            </div>
            <div class="grid">
                <div class="col-12" :class="{'sm:col-6': !form.id}">
                    <div class="field">
                        <label class="text-black text-14 roboto-medium">
                            Username *
                        </label>
                        <InputText type="email" placeholder="Username | Email" v-model="form.username" :disabled="form.is_submit" />
                    </div>
                </div>
                <div class="col-12 sm:col-6" v-if="!form.id">
                    <div class="field mb-0">
                        <label class="text-black text-14 roboto-medium">
                            Password *
                        </label>
                        <Password v-model="form.password"
                            placeholder="*************"
                            :toggleMask="true"
                            :feedback="false"
                            :disabled="form.is_submit">
                        </Password>
                    </div>
                </div>
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Role *
                </label>
                <Dropdown v-model="form.selected_role" :options="roles" optionLabel="name" placeholder="Pilih Role" />
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'user-form',
    props: ['data'],
    data() {
        return {
            form: {
                id: null,
                username: '',
                password: '',
                fullname: '',
                status: 1,
                selected_role: '',
                role: '',
                is_submit: false,
            },
            roles: [
                {name: 'ADMIN'},
                {name: 'SUPERADMIN'},
            ],
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.form.role = this.form.selected_role ? this.form.selected_role['name'] : ''
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (this.data) {
            this.form = {...this.form, ...this.data}
            this.form.status = this.form.status ? true : false
            this.form.selected_role = this.roles.find(obj => obj.name == this.form.role);
        }
    },
    methods: {
        
    },
}
</script>

<style lang="scss" scoped>

</style>